import { useCallback, useEffect, useState } from "react";
import { Work } from "../model/work";

const mockData = [
  {
    title: "Scrum Poker",
    description: "A tool for scrum poking ceremony",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/wanunt-9d51c.appspot.com/o/oakraw%2Fimages%2Fscrum-poker-bg.jpg?alt=media",
    externalUrl: "https://scrumpoker.oakraw.com/",
    color: "#000",
  },
  {
    title: "Token Swap Watcher",
    description: "A tool for tracking swap transaction of LP on BSC",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/wanunt-9d51c.appspot.com/o/oakraw%2Fimages%2Ftoken-swap-bg.jpg?alt=media",
    externalUrl: "https://wanunt-9d51c.web.app/",
    color: "#000",
  },
  {
    title: "กี่คนแล้ว",
    description: "An open source android application for covid info tracking",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/wanunt-9d51c.appspot.com/o/oakraw%2Fimages%2Fhow-many-people-bg.jpg?alt=media",
    externalUrl: "https://github.com/oakraw/thailand-covid-case-android",
    color: "#fff",
  },
  {
    title: "NocNoc",
    description: "An application of NocNoc.com",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/wanunt-9d51c.appspot.com/o/oakraw%2Fimages%2Fnocnoc-bg.jpg?alt=media",
    externalUrl: "https://play.google.com/store/apps/details?id=com.nocnoc.app.prod",
    color: "#fff",
  },
  {
    title: "Home Service",
    description: "An application for Sansiri Family",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/wanunt-9d51c.appspot.com/o/oakraw%2Fimages%2Fsansiri-bg.jpg?alt=media",
    externalUrl: "https://play.google.com/store/apps/details?id=com.homeservice",
    color: "#000",
  },
  {
    title: "Chayen",
    description: "Word guessing game, play with fliping the phone",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/wanunt-9d51c.appspot.com/o/oakraw%2Fimages%2Fchayen-bg.jpg?alt=media",
    externalUrl: "https://play.google.com/store/apps/details?id=com.volevi.chayen",
    color: "#fff",
  },
  {
    title: "Giddylizer",
    description: "Decorate a photo with the stickers.",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/wanunt-9d51c.appspot.com/o/oakraw%2Fimages%2Fgiddylizer-bg.jpg?alt=media",
    externalUrl: "https://play.google.com/store/apps/details?id=com.volevi.giddylizer.app",
    color: "#000",
  },
  {
    title: "Sozoom",
    description: "Zoom a particular point in a photo with the zooming bubble",
    imageUrl: "https://firebasestorage.googleapis.com/v0/b/wanunt-9d51c.appspot.com/o/oakraw%2Fimages%2Fsozoom-bg.jpg?alt=media",
    externalUrl: "https://www.blognone.com/node/70904",
    color: "#000",
  },
]


const useFetchWork = (): { works: Work[] | null; fetch: () => void } => {
  const [works, setWorks] = useState<Work[] | null>(null);
  const fetch = useCallback(async () => {
    setWorks(mockData);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { works, fetch };
};

export default useFetchWork;
