import {
  AspectRatio,
  Box,
  Card,
  ChakraProps,
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import React from "react";

interface WorkTileProps extends ChakraProps {
  title: string;
  description: string;
  imageUrl: string;
  externalUrl?: string;
  color?: string;
}

const WorkTile: React.FC<WorkTileProps> = (props) => {
  return (
    <Link href={props.externalUrl} isExternal>
      <AspectRatio boxSize="100%" minW={40} maxW={40} ratio={3 / 4}>
        <Card
          background="background.primary"
          borderRadius={16}
          borderWidth={1}
          borderColor="blackAlpha.100"
          shadow={0}
          position="relative"
        >
          <Image
            objectFit="cover"
            w="100%"
            h="100%"
            src={props.imageUrl}
            position="absolute"
          />
          <Box
            w="100%"
            h="100%"
            background="blackAlpha.50"
            position="absolute"
          />
          <Flex
            position="absolute"
            direction="column"
            alignItems="start"
            justifyContent="start"
            p={4}
            h="100%"
          >
            <Heading size="md" color={props.color ?? "white"}>
              {props.title}
            </Heading>
            <Text fontSize="sm" textColor={props.color ?? "gray.100"} noOfLines={4} mt={2}>
              {props.description}
            </Text>
          </Flex>
        </Card>
      </AspectRatio>
    </Link>
  );
};

export default WorkTile;
