import {
  AspectRatio,
  Avatar,
  Box,
  Card,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  Link,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import CoverImage from "../../assets/images/cover.jpg";
import ProfileImage from "../../assets/images/profile.jpeg";
import BlogCard from "../../components/BlogCard";
import WorkTile from "../../components/WorkTile";
import useFetchBlog from "../../hooks/useFetchBlog";
import useFetchWork from "../../hooks/useFetchWork";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../utils/firebase";

const Home: React.FC = () => {
  const { blogs } = useFetchBlog();
  const { works } = useFetchWork();

  useEffect(() => {
    logEvent(analytics, 'home_screen_viewed');
  }, [])

  return (
    <Box bg="gray.100">
      <Card bg="white" py="4" borderRadius={0}>
        <Container maxW="1024px">
          <AspectRatio boxSize="100%" ratio={28 / 9}>
            <Image objectFit="cover" src={CoverImage} borderRadius={16} />
          </AspectRatio>
          <Flex
            direction="row"
            mt={{ base: -4, md: -8 }}
            alignItems="end"
            ml={4}
          >
            <Avatar
              showBorder
              size={{ base: "xl", md: "2xl" }}
              src={ProfileImage}
            />
            <Flex direction="column" mb={2} ml={4}>
              <Heading
                as="h2"
                size={{ base: "md", md: "lg" }}
                mb={0}
                noOfLines={1}
              >
                Rawipol Wajanavisit
              </Heading>
              <Text fontSize={{ base: "lg", md: "2xl" }} color="gray.600">
                Software Engineer
              </Text>
            </Flex>
          </Flex>
          <Heading mt={8} size="md">
            My Works
          </Heading>
        </Container>
        <Container
          maxW={{ base: "100%", md: "1024px" }}
          px={{ base: 0, md: 4 }}
        >
          <HStack
            mt={4}
            spacing={4}
            alignItems="start"
            overflowX="auto"
            overflowY="hidden"
            px={{ base: 4, md: 0 }}
          >
            {works &&
              works.map((work, index) => (
                <WorkTile
                  key={index}
                  title={work.title}
                  description={work.description}
                  imageUrl={work.imageUrl}
                  externalUrl={work.externalUrl}
                  color={work.color}
                />
              ))}
          </HStack>
        </Container>
      </Card>
      <Container maxW="1024px">
        <Flex direction="row" justifyContent="space-between" alignItems="baseline">
          <Heading size="xl" mt={8}>
            Articles
          </Heading>
          <Link href={blogs?.feed.link} isExternal color="primary">
            View More
          </Link>
        </Flex>
        <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={4} py={4}>
          {blogs &&
            blogs.items.map((blog, index) => (
              <BlogCard blog={blog} key={index} />
            ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default Home;
