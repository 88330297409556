import {
  Card,
  CardBody,
  CardFooter,
  ChakraProps,
  Heading,
  LinkOverlay,
  Text,
  Image,
  LinkBox,
  Flex,
  AspectRatio,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { BlogItem } from "../model/blog";
import { format } from "date-fns";

interface BlogCardProps extends ChakraProps {
  blog: BlogItem;
}

const extractImageFromHtml = (content: string) => {
  const regex = /(https?:\/\/.*\.(?:png|jpg|jpeg))/i;
  const images = regex.exec(content);
  return images ? images[0] : "";
};

const BlogCard: React.FC<BlogCardProps> = (props) => {
  return (
    <LinkBox as="article">
      <Card
        direction={{ base: "column", sm: "column" }}
        overflow="hidden"
        variant="filled"
        bg="white"
        borderRadius={16}
        h="100%"
      >
        <Box w="100%" p={4}>
          <AspectRatio boxSize="100%" ratio={16 / 9}>
            <Image
              objectFit="cover"
              maxW={{ base: "100%" }}
              src={
                props.blog.thumbnail || extractImageFromHtml(props.blog.content)
              }
              alt={props.blog.title}
              borderRadius={12}
            />
          </AspectRatio>
        </Box>

        <Flex direction="column" h="100%" justifyContent="space-between">
          <CardBody p={4}>
            <LinkOverlay href={props.blog.link} isExternal>
              <Heading size="md">{props.blog.title}</Heading>
            </LinkOverlay>
          </CardBody>

          <CardFooter p={4}>
            <Text>{format(Date.parse(props.blog.pubDate), "MMM d, yyyy")}</Text>
          </CardFooter>
        </Flex>
      </Card>
    </LinkBox>
  );
};

export default BlogCard;
