import { extendTheme } from '@chakra-ui/react';

// Step 1. Customising our button component
// - We want to update all buttons to use a bold font weight
// - We also want to add a box shadow to our "Open modal" button
// - (Make sure the box shadow colour updates with dark mode)
// - Get started by checking out the following documentation:
// https://chakra-ui.com/docs/theming/customize-theme
interface BaseStyleProps {
  colorMode: string;
}

const components = {
  Card: {
    baseStyle: ({ colorMode }: BaseStyleProps) => ({
      bg: colorMode === 'dark' ? 'background.dark' : 'white',
    }),
  },
  GrayCard: {
    baseStyle: ({ colorMode }: BaseStyleProps) => ({
      bg: colorMode === 'dark' ? 'background.dark' : 'background.neutral',
    }),
  },
  PrimaryCard: {
    baseStyle: ({ colorMode }: BaseStyleProps) => ({
      bg: colorMode === 'dark' ? 'background.dark' : 'background.primary',
    }),
  },
  Background: {
    baseStyle: ({ colorMode }: BaseStyleProps) => ({
      bg: colorMode === 'dark' ? 'gray.800' : 'white',
    }),
  },
};

const theme = extendTheme({
  fonts: {
    body: 'IBM Plex Sans Thai, sans-serif',
    heading: 'IBM Plex Sans Thai, sans-serif',
  },
  colors: {
    background: {
      dark: '#2c313d',
      neutral: '#F9F9F9',
      primary: '#EBF8FF',
    },
    primary: '#3182CE',
    secondary: '#CF6F3C',
    error: '#e5413e',
    title: {
      active: '#070409',
    },
  },
  components,
  initialColorMode: 'light',
  useSystemColorMode: false,
});

export default theme;
