import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDUDMw-Ws5plYKMt7Q9YBPo7X5r6l8eQuk",
  authDomain: "wanunt-9d51c.firebaseapp.com",
  databaseURL: "https://wanunt-9d51c.firebaseio.com",
  projectId: "wanunt-9d51c",
  storageBucket: "wanunt-9d51c.appspot.com",
  messagingSenderId: "578061578345",
  appId: "1:578061578345:web:be8ebfa2c4c8cbf1a372f8",
  measurementId: "G-5F4YVVFTEE",
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
