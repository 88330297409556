import {
  ChakraProvider
} from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./views/Home";
import NotFound from "./views/NotFound";
import theme from './theme';

export const App = () => (
  <Router>
    <ChakraProvider theme={theme}>
        <Routes>
          <Route index element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
    </ChakraProvider>
  </Router>
);
